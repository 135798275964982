<template>
  <div
    v-if="$route.name === 'index'"
    class="w-block__footer"
    :class="{
      'w-block__footer--mobile': $voicer.isMobile,
      'w-block__footer--ios': $voicer.isIos,
      'w-block__footer--no-player': isPersistantVisible === false,
    }"
  >
    <!-- footer block -->
    <div class="pa-4">
      <div class="body-2 mb-3">
        Contactez-nous et laissez-nous un message par mail sur
        <a href="mailto:contact@patients-ensemble.fr"
          >contact@patients-ensemble.fr</a
        >
      </div>
      <div class="body-2 mb-3">
        Patients Ensemble est aussi disponible sur :
      </div>
      <div class="mt-1 mb-4">
        <img
          :src="require('~/assets/images/deezer.svg')"
          height="19"
          alt="logo-deezer"
          class="mr-4"
          @click="handleOpen('https://deezer.page.link/LytoQ8oWLodA3bJbA')"
        />
        <img
          :src="require('~/assets/images/google-podcast.svg')"
          height="19"
          alt="logo-googlepodcast"
          class="mr-4"
          @click="
            handleOpen(
              'https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkLmF1c2hhLmNvL3l2cDNtVVhER0UzWA=='
            )
          "
        />
        <img
          :src="require('~/assets/images/spotify.svg')"
          height="23"
          alt="logo-spotify"
          class="mr-4"
          @click="
            handleOpen(
              'https://open.spotify.com/show/2UAIwoD8wdS6jeOP1qUiUD?si=6QaDG-ZBTYS4dEGJXZOvcA'
            )
          "
        />
        <img
          :src="require('~/assets/images/ausha.svg')"
          height="23"
          alt="logo-ausha"
          class="mr-4"
          @click="handleOpen('https://podcast.ausha.co/patients-ensemble')"
        />
        <img
          :src="require('~/assets/images/apple-podcast.svg')"
          height="28"
          alt="logo-applepodcast"
          class="mr-4"
          @click="
            handleOpen(
              'https://podcasts.apple.com/us/podcast/patients-ensemble/id1516333035?uo=4'
            )
          "
        />
      </div>
      <hr size class="mt-4 mb-4" color="#ea541e" />
      <div class="body-2 mb-3">Avec la participation de :</div>
      <div class="mt-1 mb-4">
        <img
          :src="require('~/assets/images/jeune&Rose-Logo.png')"
          height="49"
          alt="logo-jeuneetrose"
          class="mr-4"
          @click="handleOpen('https://www.jeuneetrose.com')"
        />
        <img
          :src="require('~/assets/images/logoaptednew.jpg')"
          height="49"
          alt="logo-apted"
          class="mr-4"
          @click="handleOpen('https://www.apted.fr')"
        />
        <img
          :src="require('~/assets/images/badge.png')"
          height="49"
          alt="logo-lesrosespoudrées"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/logo_acromegale.png')"
          height="49"
          alt="logo-acromegale"
          class="mr-4"
        />
        <img
          :src="require('~/assets/images/cerllom.png')"
          height="49"
          alt="logo-cerllom"
          class="mr-4"
          @click="handleOpen('http://cerhom.fr/')"
        />
        <img
          :src="require('~/assets/images/patients-en-r_carré.png')"
          height="49"
          alt="logo-patientenréseaux"
          class="mr-4"
          @click="handleOpen('http://www.monreseau-cancerdusein.com')"
        />
        <img
          :src="require('~/assets/images/fabrique_creative.png')"
          height="49"
          alt="logo-patientenréseaux"
          class="mr-4"
          @click="handleOpen('https://www.fabriquecreativedesante.fr/')"
        />
      </div>
      <div class="mt-1 mb-4">
        <img
          :src="require('~/assets/images/cami_logo.png')"
          height="39"
          alt="logo-cami"
          class="mr-4"
          @click="handleOpen('https://www.sportetcancer.com/')"
        />
        <img
          :src="require('~/assets/images/logo_uniLasalle.jpg')"
          height="49"
          alt="logo-unilasalle"
          class="mr-4"
          @click="handleOpen('https://www.unilasalle.fr/')"
        />
        <img
          :src="require('~/assets/images/ucorange.jpg')"
          height="39"
          alt="logo-uniscite"
          class="mr-4"
          @click="handleOpen('http://www.uniscite.fr/')"
        />
      </div>
      <hr size="1" class="mt-4 mb-4" color="#ea541e" />
      <div class="body-2 mb-3">Notre partenaire</div>
      <div>
        <img
          :src="require('~/assets/images/airzenlogo.png')"
          height="59"
          alt="logo-ipsen"
        />
      </div>
      <div>
        <v-btn
          color="primary"
          href="https://www.airzen.fr/"
          target="_blank"
          rounded
          depressed
          >En savoir plus</v-btn
        >
      </div>
      <hr class="mt-4 mb-4" size="1" color="#ea541e" />
      <div class="body-2 mb-2">Avec le soutien institutionnel de</div>
      <div>
        <img
          :src="require('~/assets/images/logoipsen.png')"
          height="39"
          alt="logo-ipsen"
          class="mr-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  methods: {
    handleOpen(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__footer {
  justify-content: center;
  text-align: center;
  border-top-style: solid;
  border-top-color: #ea541e;
  background-color: white;
  padding-bottom: 82px;
  color: #000000;
  &--mobile {
    padding-bottom: 140px;
  }
  &--ios {
    padding-bottom: 190px;
  }
  &--no-player {
    padding-bottom: 20px;
  }
}
hr {
  height: 1px;
  background-color: #d3d3d3;
  width: 40%;
  margin: 1px;
  border: none;
  margin: auto;
}
</style>

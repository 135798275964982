var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-block__pre-heading",class:{ 'ml-4 mr-4 mt-3': _vm.$voicer.isMobile }},[(_vm.$route.name === 'index')?_c('v-container',{staticClass:"pa-2"},[_c('v-sheet',{staticClass:"pa-2",attrs:{"color":"primary","dark":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-text',{staticClass:"w-block__body2",class:{
            'pa-2': _vm.$voicer.isMobile === false,
            'pa-1': _vm.$voicer.isMobile === true,
            headline: _vm.$voicer.isMobile,
            title: _vm.$voicer.isMobile === false,
          }},[_vm._v("\n          Patients Ensemble, les podcasts par et pour les patients : chaque\n          mardi & jeudi.\n        ")]),_vm._v(" "),_c('p',{staticClass:"body-2"},[_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen(
                'https://www.facebook.com/Patients-Ensemble-108805940936951'
              )}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-facebook ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen('https://www.instagram.com/patientsensemble/?hl=fr')}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-instagram ")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.handleOpen('https://www.linkedin.com/showcase/76940322/admin/')}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-linkedin ")])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }